var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    [
      _c(
        "vs-prompt",
        {
          staticClass: "export-options",
          attrs: {
            title: "Export To Excel",
            "accept-text": "Export",
            active: _vm.activePrompt,
          },
          on: {
            cancle: _vm.clearFields,
            accept: _vm.exportToExcel,
            close: _vm.clearFields,
            "update:active": function ($event) {
              _vm.activePrompt = $event
            },
          },
        },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { placeholder: "Enter File Name.." },
            model: {
              value: _vm.fileName,
              callback: function ($$v) {
                _vm.fileName = $$v
              },
              expression: "fileName",
            },
          }),
          _c("v-select", {
            staticClass: "my-4",
            attrs: { options: _vm.formats },
            model: {
              value: _vm.selectedFormat,
              callback: function ($$v) {
                _vm.selectedFormat = $$v
              },
              expression: "selectedFormat",
            },
          }),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("span", { staticClass: "mr-4" }, [_vm._v("Cell Auto Width:")]),
              _c(
                "vs-switch",
                {
                  model: {
                    value: _vm.cellAutoWidth,
                    callback: function ($$v) {
                      _vm.cellAutoWidth = $$v
                    },
                    expression: "cellAutoWidth",
                  },
                },
                [_vm._v("Cell Auto Width")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "export-table" },
        [
          _c(
            "vs-table",
            {
              attrs: { data: _vm.users, search: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr },
                        [
                          _c("vs-td", [_vm._v(_vm._s(data[indextr].email))]),
                          _c("vs-td", [_vm._v(_vm._s(data[indextr].name))]),
                          _c("vs-td", [_vm._v(_vm._s(data[indextr].website))]),
                          _c("vs-td", [_vm._v(_vm._s(data[indextr].id))]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "vs-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.activePrompt = true
                        },
                      },
                    },
                    [_vm._v("Export")]
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v(" Email   ")]),
                  _c("vs-th", [_vm._v(" Name    ")]),
                  _c("vs-th", [_vm._v(" Website ")]),
                  _c("vs-th", [_vm._v(" Nro     ")]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }